@media screen and (min-width: 1050px){
    .container {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        .contents{
            width: 72%;
        }
        .table-of-contents{
            padding: 10px;
            border-left: 2px solid #efefef;
            width: 28%;
            position: -webkit-sticky;
            position: sticky;
            top: 80px;
            height: fit-content;
            margin-left: 30px;
            ul{
                margin-left: 20px;
                list-style-type: revert;
                font-size: 14px;
                line-height: 24px;
                color: #005b81;
                a{
                    color: #005b81;
                    &:hover{
                        color: #e32e00;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1050px) {
    .post-content {
        .container {
            width: 100%;
            .contents {
                width: 100%;
                float: none;
                margin: 0 auto;
            }
            .table-of-contents {
                display: none;
            }
        }
    }
}
